import * as colorConfig from './app/lib/colors'

import defaultTheme from 'tailwindcss/defaultTheme'

import type { Config } from 'tailwindcss'

const config = {
  content: ['./app/**/*.{js,jsx,ts,tsx}', 'components/**/*.{ts,tsx}'],
  theme: {
    colors: colorConfig.colors,
    fontFamily: {
      sans: ['PPMori', ...defaultTheme.fontFamily.sans],
      legacy: ['Inter', ...defaultTheme.fontFamily.sans],
    },
    extend: {
      borderWidth: {
        3: '3px',
      },
      screens: {
        xs: '495px',
        '3xl': '1920px',
      },
      boxShadow: {
        'inset-neon-flame': `inset 0 0 0 2px ${colorConfig.colors['brand-neon'].flame}`,
        'inset-brand-blue': `inset 0 0 0 1px ${colorConfig.colors['brand-blue'][500]}`,
        'inset-brand-blue-2': `inset 0 0 0 2px ${colorConfig.colors['brand-blue'][500]}`,
      },
      backgroundImage: {
        'topographic-map':
          "url('https://res.cloudinary.com/abatable-com/image/upload/f_auto,q_auto/v1717157765/topographic-bg_p7k1wu')",
        'mountainous-landscape':
          'url("https://res.cloudinary.com/abatable-com/image/upload/f_auto,q_auto/v1717157765/drone-shot-mountainous-terrain_p3rdoy.jpg")',
        'river-through-forest':
          'url("https://res.cloudinary.com/abatable-com/image/upload/f_auto,q_auto/v1723718358/river-through-forest_hdnogd.jpg")',
      },
      keyframes: {
        'accordion-down': {
          from: { height: '0' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        'accordion-up': {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0' },
        },
        'animate-in': {
          from: { opacity: '0' },
          to: { opacity: '1' },
        },
        'animate-out': {
          from: { opacity: '1' },
          to: { opacity: '0' },
        },
        enter: {
          from: {
            opacity: 'var(--tw-enter-opacity, 1)',
            transform:
              'translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0))',
          },
        },
        leave: {
          to: {
            opacity: 'var(--tw-exit-opacity, 1)',
            transform:
              'translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0))',
          },
        },
        'slide-in-from-right': {
          from: { transform: 'translateX(100%)' },
          to: { transform: 'translateX(0)' },
        },
        'slide-out-to-right': {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(100%)' },
        },
        'slide-in-from-left': {
          from: { transform: 'translateX(-100%)' },
          to: { transform: 'translateX(0)' },
        },
        'slide-out-to-left': {
          from: { transform: 'translateX(0)' },
          to: { transform: 'translateX(-100%)' },
        },
        'slide-in-from-bottom': {
          from: { transform: 'translateY(100%)' },
          to: { transform: 'translateY(0)' },
        },
        'slide-out-to-bottom': {
          from: { transform: 'translateY(0)' },
          to: { transform: 'translateY(100%)' },
        },
        'slide-in-from-top': {
          from: { transform: 'translateY(-100%)' },
          to: { transform: 'translateY(0)' },
        },
        'slide-out-to-top': {
          from: { transform: 'translateY(0)' },
          to: { transform: 'translateY(-100%)' },
        },
      },
      animation: ({ theme }) => ({
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        out: `leave 0.2s ${theme('animationTimingFunction.DEFAULT')} ${theme(
          'animationDelay.DEFAULT',
        )} forwards`,
        in: `enter  0.2s ${theme('animationTimingFunction.DEFAULT')} ${theme(
          'animationDelay.DEFAULT',
        )} forwards`,
        'fade-in': 'animate-in 0.2s ease-out',
        'fade-out': 'animate-out 0.2s ease-out',
        'slide-in-from-right': 'slide-in-from-right 0.2s ease-out',
        'slide-out-to-right': 'slide-out-to-right 0.2s ease-out',
        'slide-in-from-left': 'slide-in-from-left 0.2s ease-out',
        'slide-out-to-left': 'slide-out-to-left 0.2s ease-out',
        'slide-in-from-bottom': 'slide-in-from-bottom 0.2s ease-out',
        'slide-out-to-bottom': 'slide-out-to-bottom 0.2s ease-out',
        'slide-in-from-top': 'slide-in-from-top 0.2s ease-out',
        'slide-out-to-top': 'slide-out-to-top 0.2s ease-out',
      }),
      animationFillMode: {
        none: 'none',
        forwards: 'forwards',
        backwards: 'backwards',
        both: 'both',
      },
      animationDirection: {
        normal: 'normal',
        reverse: 'reverse',
        alternate: 'alternate',
        'alternate-reverse': 'alternate-reverse',
      },
      animationRepeat: {
        0: '0',
        1: '1',
        infinite: 'infinite',
      },
    },
  },
} satisfies Config

export default config
