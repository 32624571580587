import { VCMIndexChart, VCMIndexChartMobile } from './components/VCMGraph'
import { VCMIndexTable, VCMIndexTableMobile } from './components/VCMIndexTable'

import { useLoaderData } from '@remix-run/react'
import groq from 'groq'
import _ from 'lodash'
import { useRef, useState } from 'react'
import { FiCheck } from 'react-icons/fi'

import { Button } from '~/components/ui/Button'
import { Container } from '~/components/ui/Container'
import { useEventListener } from '~/lib/hooks/useEventListener'
import { ROUTES } from '~/lib/routes'
import { cn } from '~/lib/utils'
import { cloudinaryService } from '~/services/cloudinary'
import { sanityService } from '~/services/sanity.server'
import { VCMIndexDataPoint, vcmIndexService } from '~/services/vcmIndex.server'

type VCMInvestmentIndexViewPageData = {
  methodologyPdfLink: string
}

const VCMInvestmentIndexViewPageDataQuery = groq`
  *[_type == "websiteVCMInvestmentIndexViewPage"][0]{
    "methodologyPdfLink": methodologyPdf.asset->url
  }
`
export async function loader() {
  const { vcmData } = await vcmIndexService.get()

  const pageData =
    await sanityService.fetchRecord<VCMInvestmentIndexViewPageData>({
      query: VCMInvestmentIndexViewPageDataQuery,
    })

  return {
    methodologyPdfLink: pageData.methodologyPdfLink || '',
    query: VCMInvestmentIndexViewPageDataQuery,
    vcmData,
  }
}

export default function VCMAttractivenessIndexPage() {
  const data = useLoaderData<typeof loader>()

  return (
    <main className="text-brand-blue-500">
      <div className="px-6 lg:px-0">
        <VCMTitleSection />
      </div>
      <h2 className="text-brand-blue-500 text-center mt-20 text-2xl lg:text-4xl">
        Top 40 ranking and Index scores
      </h2>

      <div
        className="w-full max-w-[1128px] mx-auto px-6 lg:px-8 bg-white mt-6 min-[1360px]:bg-none min-[1360px]:mt-10 min-[1128px]:rounded-lg"
        aria-label="Chart of top 40 ranking and index scores"
      >
        <VCMChart vcmData={data.vcmData} />
      </div>

      <div
        className="mt-[74px] px-6 lg:px-8"
        aria-label="Table of top 40 ranking and index scores"
      >
        <div className="max-w-[1128px] mx-auto">
          <VCMTable vcmData={data.vcmData} />
        </div>
      </div>

      <div>
        <Container centered>
          <hr className="text-grey-200 my-10" />
          <VCMMethodology methodologyPdfLink={data.methodologyPdfLink} />
        </Container>
      </div>
      <PolicyProfilesSection />
      <div>
        <MarketIntelligenceSolutions />
      </div>
    </main>
  )
}

export function VCMTitleSection() {
  return (
    <div className="pt-12 lg:pt-24">
      <h1 className="text-4xl max-w-[720px] mx-auto lg:text-6xl text-center">
        VCM Investment Attractiveness Index
      </h1>

      <InCollaborationWithHowden />

      <div className="text-lg lg:text-xl space-y-6 lg:space-y-8 text-center mt-10 lg:mt-16 max-w-[570px] leading-8 lg:max-w-[730px] mx-auto">
        <p>
          The VCM Investment Attractiveness Index evaluates and ranks countries
          on the attractiveness of their voluntary carbon market investment and
          development opportunities, using indicators reflecting national
          economic, political and environmental conditions. Explore our ranking
          of the top 40 countries and find out how the list was compiled.
        </p>
      </div>
    </div>
  )
}

function InCollaborationWithHowden() {
  return (
    <div className="flex items-center justify-center uppercase mt-6 text-center gap-2 text-brand-blue-500 text-[13px] tracking-[1.3px]">
      <p className="mt-1">In collaboration with</p>
      <img
        src={cloudinaryService.loadImage({
          src: 'howden-hd_y1gyyy.png',
          width: 150,
        })}
        width={100}
        alt="Howden logo"
      />
    </div>
  )
}

function VCMMethodology(props: { methodologyPdfLink: string }) {
  return (
    <div className="mb-12 lg:mb-24 text-brand-blue-500" id="methodology">
      <h2 className="text-xl mb-6 lg:mb-5">Our methodology</h2>

      <div className="text-lg space-y-6 lg:space-y-8">
        <p>
          The VCM Investment Attractiveness Index was designed to provide
          capital allocation risk insights to investors and to aid governments
          in setting carbon market-friendly policies. It is built around three
          pillars which analyse countries&apos; investment landscapes, their
          readiness to engage with new Paris Agreement carbon markets and the
          opportunity for the VCM to improve environmental and social
          conditions.
        </p>
        <p>
          You can also{' '}
          <a
            href={ROUTES.MARKETING_SITE.BLOG.VCM_INDEX_INSIGHTS}
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium text-brand-blue-500 underline"
          >
            read more on our blog
          </a>
          .
        </p>
        <div className="w-full md:w-[410px]">
          <Button
            href={props.methodologyPdfLink}
            variant="outline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Download methodology
          </Button>
        </div>
      </div>
    </div>
  )
}

function PolicyProfilesSection() {
  return (
    <div className="bg-brand-green-100 py-20 overflow-hidden text-brand-blue-500">
      <div className="flex flex-wrap md:flex-nowrap md:justify-between mx-auto md:flex-row md:max-w-[1128px] xl:w-[1128px]">
        <div className="flex-none px-6 w-full md:max-w-[28rem] lg:max-w-[33rem]">
          <h2 className="text-2xl lg:text-4xl md:max-w-[24rem] lg:max-w-none">
            Get access to our country policy profiles
          </h2>
          <p className="mt-5 mb-6 text-lg lg:mt-6 lg:mb-2 lg:text-xl">
            Understand and minimise host nation policy risk in your carbon
            strategies.
          </p>
          <ul
            className="mt-5 mb-6 text-lg lg:mt-4 lg:mb-8 lg:text-xl list-outside space-y-2"
            aria-label={`List of features of Abatable's forthcoming country policy profiles`}
          >
            <li className="flex gap-4 items-start">
              <div className="w-6 p-1">
                <FiCheck size={24} className="text-brand-blue-500" />
              </div>
              <span>
                Informed policy insights backed up by transparent methodology
              </span>
            </li>
            <li className="flex gap-4 items-start">
              <div className="w-6 p-1">
                <FiCheck size={24} className="text-brand-blue-500" />
              </div>
              <span>
                Analytical and structured approach for comparing investment
                risks
              </span>
            </li>
            <li className="flex gap-4 items-start">
              <div className="w-6 p-1">
                <FiCheck size={24} className="text-brand-blue-500" />
              </div>
              <span>Access to expert insights with focus on the VCM</span>
            </li>
          </ul>

          <div className="w-full md:w-[175px]">
            <Button
              href="/country-policy-profiles"
              size="base"
              variant="outline"
            >
              Learn more
            </Button>
          </div>
        </div>

        <div className="relative flex-1 mt-6 lg:mt-0 md:ml-10 h-[470px] w-full overflow-hidden lg:ml-20 pt-10">
          <img
            src={cloudinaryService.loadImage({
              src: 'policy-moduke-showcase-full_k69ru9.jpg',
              width: 850,
            })}
            width={750}
            height={450}
            alt="Mexico policy profile preview"
            className="absolute -right-2 lg:right-2"
          />
        </div>
      </div>
    </div>
  )
}

function MarketIntelligenceSolutions() {
  const solutions = [
    {
      title: 'Pricing intelligence',
      image: 'picing_module_yqnuvj',
      description:
        'Explore forward price curves based on over 36,000 price points and real transactional data from Abatable. Compare median prices for different credit vintages over time.',
    },
    {
      title: 'Supply insights',
      image: 'supply-module_e1ympt',
      description:
        'Access data from over 3,000 carbon project developers tracked by Abatable, all in one place. Track funding and issuance data by developer and understand forecast supply.',
    },
    {
      title: 'Market updates',
      image: 'market-updates-module_eqsg44',
      description:
        'Stay on top of the latest market trends through curated insights from Abatable’s expert in-house team.',
    },
    {
      title: 'Integrity analysis',
      image: 'quality-module_bds0g9',
      description:
        'Avoid integrity risk by accessing in-depth breakdowns of project-type methodologies.',
    },
  ]

  return (
    <Container
      centered
      className="pt-24 text-brand-blue-500 pb-14 lg:pt-28 lg:pb-40 flex items-center justify-center flex-col mx-auto"
    >
      <h2 className="md:text-4xl max-w-[22rem] md:max-w-[37rem] pb-6 text-center text-[26px] leading-8">
        Register your interest in our upcoming intelligence solutions
      </h2>

      <p className="max-w-[22rem] md:max-w-[37rem] text-center lg:text-xl text-lg pb-11">
        Market intelligence solutions to answer the most critical questions in
        the VCM, powered by our technical expertise and proprietary data.
      </p>
      <div className="grid grid-cols-12 px-4 lg:max-w-3xl text-brand-blue-500 lg:gap-10">
        {solutions.map((solution, idx) => (
          <div key={solution.title} className="col-span-12 lg:col-span-6 p-6">
            <div className="bg-brand-green-100 rounded-2xl p-4 w-fit">
              <div className="bg-brand-green-100 w-[250px] sm:w-[290px] h-[212px] relative rounded-lg overflow-hidden">
                <div
                  className={cn(
                    'absolute bg-brand-green-100',
                    idx === 3
                      ? '-top-2 -left-2 w-[260px] sm:w-[320px]'
                      : 'w-[250px] sm:w-[290px] top-0',
                  )}
                >
                  <img
                    src={cloudinaryService.loadImage({
                      src: `abatable.com/${solution.image}`,
                      width: 320,
                    })}
                    width={320}
                    height={230}
                    alt={'Image for' + solution.title + 'product'}
                  />
                </div>
              </div>
            </div>
            <h4 className="text-xl mt-4 lg:mt-3">{solution.title}</h4>
            <p className="mt-4 lg:mt-3">{solution.description}</p>
            <Button
              className="mt-4"
              key={solution.title}
              variant="outline"
              href={ROUTES.MARKETING_SITE.MARKET_INTELLIGENCE}
            >
              Request a demo
            </Button>
          </div>
        ))}
      </div>
    </Container>
  )
}

function VCMChart({ vcmData }: { vcmData: VCMIndexDataPoint[] }) {
  const [key, setKey] = useState(1)

  const updateKey = useRef(
    _.debounce(() => {
      setKey((key) => key + 1)
    }, 50),
  )

  useEventListener('resize', updateKey.current)

  // important
  //
  // using min-[1360px] here as a custom breakpoint for this page so that we
  // wrap immediately to mobile view when the screen is too small to display
  // the full desktop table. We never want the table to scroll, and it's pixel perfect
  // in terms of column widths etc, so it itself is not responsive.
  return (
    <div key={key}>
      <div className="hidden [@media(min-width:1360px)]:block mx-auto max-w-[1308px]">
        <VCMIndexChart data={vcmData} />
      </div>
      <div className="block [@media(min-width:1360px)]:hidden">
        <VCMIndexChartMobile data={vcmData} />
      </div>
    </div>
  )
}

function VCMTable({ vcmData }: { vcmData: VCMIndexDataPoint[] }) {
  // important
  //
  // using [@media(min-width:1360px)] here as a custom breakpoint for this page so that we
  // wrap immediately to mobile view when the screen is too small to display
  // the full desktop table. We never want the table to scroll, and it's pixel perfect
  // in terms of column widths etc, so it itself is not responsive.
  return (
    <>
      <div className="justify-center hidden [@media(min-width:1360px)]:flex">
        <VCMIndexTable data={vcmData} />
      </div>
      <div className="[@media(min-width:1360px)]:hidden flex flex-col">
        <VCMIndexTableMobile data={vcmData} />
      </div>
    </>
  )
}
