import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react'
import {
  CellContext,
  Header,
  Cell,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import React, { useMemo, useState, useRef, Fragment } from 'react'
import { HiCheck, HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { HiChevronUpDown } from 'react-icons/hi2'
import invariant from 'tiny-invariant'

import { Tooltip } from '~/components/abatable-ui/Tooltip'
import { CountryFlag } from '~/components/ui/Flag'
import { cn } from '~/lib/utils'
import { VCMIndexDataPoint } from '~/services/vcmIndex.server'

type Props = {
  data: VCMIndexDataPoint[]
}

function createSubPillarColumnConfig(
  header: string,
  accessorFn: (index: VCMIndexDataPoint) => string | number | null,
) {
  return {
    header,
    accessorFn,
    accessorKey: header,
    enableSorting: false,
    size: 104,
    cell: (index: CellContext<VCMIndexDataPoint, unknown>) => {
      const country = index.row.original.country
      invariant(typeof country === 'string')

      const subPillar = index.cell.column.columnDef.header
      invariant(typeof subPillar === 'string')

      const score = accessorFn(index.row.original)

      return (
        <IndexTableCellTooltip
          CellContent={score}
          tooltip={{
            country,
            subPillar,
            score,
          }}
        />
      )
    },
  }
}

// custom re-rending of specific country names to have them fit on one line in the table
export function VCMCountryNameShortRendering(props: { name: string }) {
  switch (props.name) {
    case `Lao People's Democratic Republic`:
      return 'Lao PDR'
    case `Dominican Republic`:
      return 'Dominican Rep.'
    default:
      return props.name
  }
}

const HEADER_TEXT = {
  LEGACY_VCM_ISSUANCES: 'Legacy VCM issuances',
  LEGACY_DEVELOPER_ECOSYSTEM: 'Legacy developer ecosystem',
  DEVELOPER_ECOSYSTEM_TODAY: 'Developer ecosystem today',
  RECENT_SUPPLY_OF_IN_DEMAND_CREDITS: 'Recent supply of in-demand credits',
  CDM_EXPERIENCE: 'CDM \nexperience',
  REDD_READINESS: 'REDD \nreadiness',
  FOREST_BASELINE_PROFICIENCY: '\nForest baseline proficiency',
  ARTICLE_SIX_ENGAGEMENT: 'Article 6 engagement',

  POLITICAL_STABILITY: 'Political \nstability',
  RECENT_COUP: 'Recent coup',
  INFLATION: 'Inflation',
  TENURE_SECURITY: 'Tenure security',
  GOVERNMENT_EXPROPRIATION_RISK: 'Government expropriation \nrisk',
  FOREIGN_DIRECT_INVESTMENT: 'Foreign direct investment',
  FINANCIAL_SANCTIONS: 'Financial sanctions',
  RULE_OF_LAW: 'Rule of law',

  CLEAN_COOKING_OPPORTUNITY: 'Clean cooking opportunity',
  ELECTRICITY_ACCESS_OPPORTUNITY: 'Electricity access opportunity',
  RENEWABLES_ADOPTION_OPPORTUNITY: 'Renewables adoption opportunity',
  OPPORTUNITY_TO_REDUCE_DEFORESTATION: 'Opportunity to reduce deforestation',
  TOTAL_BIOMASS: 'Total biomass',
  BIOMASS_CONCENTRATION_IN_FORESTS: 'Biomass concentration \nin forests',
  BIODIVERSITY_CONSERVATION_POTENTIAL: 'Biodiversity conservation potential',
  CO2_REDUCTION_OPPORTUNITY: 'CO2 reduction opportunity',
}

export function VCMIndexTable(props: Props) {
  const vcmIndexData = props.data

  const overallColumns = useMemo<Array<ColumnDef<VCMIndexDataPoint>>>(
    () => [
      {
        header: 'Rank',
        accessorKey: 'rank',
        cell: (index) => (
          <div className="font-medium">{index.row.original.rank}</div>
        ),
        size: 84,
      },
      {
        header: 'Country',
        accessorKey: 'country',
        cell: (index) => (
          <div className="font-semibold flex gap-5 items-center">
            <CountryFlag
              className="mb-[3px]"
              name={index.row.original.countryCode}
              width={34}
            />
            <p>
              <VCMCountryNameShortRendering name={index.row.original.country} />
            </p>
          </div>
        ),
        enableSorting: false,
        size: 298,
      },
      {
        header: 'Overall score',
        accessorKey: 'score',
        cell: (index) => (
          <div className="font-bold">{index.row.original.score.toFixed(1)}</div>
        ),
        size: 105,
      },
      {
        header: 'Global carbon \nmarket readiness',
        accessorFn: (index) => index.globalCarbonMarketReadiness.score,
        size: 308,
      },
      {
        header: 'Investment \nlandscape',
        accessorFn: (index) => index.investmentLandscape.score,
        size: 308,
      },
      {
        header: 'Climate, nature \nand people opportunity',
        accessorFn: (index) => index.climateNaturePeopleOpportunity.score,
        size: 308,
        enableHiding: true,
      },
    ],
    [],
  )

  const globalCarbonMarketReadinessColumns = useMemo<
    Array<ColumnDef<VCMIndexDataPoint>>
  >(
    () => [
      {
        header: 'Rank',
        accessorKey: 'rank',
        cell: (index) => (
          <div className="font-medium">{index.row.original.rank}</div>
        ),
        size: 30,
      },
      {
        header: 'Country',
        accessorKey: 'country',
        cell: (index) => (
          <div className="font-semibold flex gap-5 items-center">
            <CountryFlag
              className="mb-[3px]"
              name={index.row.original.countryCode}
              width={34}
            />
            <p>
              <VCMCountryNameShortRendering name={index.row.original.country} />
            </p>
          </div>
        ),
        enableSorting: false,
        size: 298,
      },
      {
        header: 'Overall score',
        accessorKey: 'score',
        cell: (index) => (
          <div className="font-bold">{index.row.original.score.toFixed(1)}</div>
        ),
        size: 105,
      },
      {
        header: 'Pillar score',
        accessorKey: 'globalCarbonMarketReadinessPillarScore',
        enableSorting: false,
        size: 104,
        accessorFn: (index: VCMIndexDataPoint) =>
          index.globalCarbonMarketReadiness.score,
      },

      createSubPillarColumnConfig(
        HEADER_TEXT.LEGACY_VCM_ISSUANCES,
        (index: VCMIndexDataPoint) =>
          index.globalCarbonMarketReadiness.legacyVCMissuances,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.LEGACY_DEVELOPER_ECOSYSTEM,
        (index: VCMIndexDataPoint) =>
          index.globalCarbonMarketReadiness.legacyDeveloperEcosystem,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.DEVELOPER_ECOSYSTEM_TODAY,
        (index: VCMIndexDataPoint) =>
          index.globalCarbonMarketReadiness.developerEcosystemToday,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.RECENT_SUPPLY_OF_IN_DEMAND_CREDITS,
        (index: VCMIndexDataPoint) =>
          index.globalCarbonMarketReadiness.recentSupplyOfInDemandCredits,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.CDM_EXPERIENCE,
        (index: VCMIndexDataPoint) =>
          index.globalCarbonMarketReadiness.CDMexperience,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.REDD_READINESS,
        (index: VCMIndexDataPoint) =>
          index.globalCarbonMarketReadiness.REDDreadiness,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.FOREST_BASELINE_PROFICIENCY,
        (index: VCMIndexDataPoint) =>
          index.globalCarbonMarketReadiness.forestBaselineProficiency,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.ARTICLE_SIX_ENGAGEMENT,
        (index: VCMIndexDataPoint) =>
          index.globalCarbonMarketReadiness.articleSixEngagement,
      ),
    ],
    [],
  )

  const investmentLandscapeColumns = useMemo<
    Array<ColumnDef<VCMIndexDataPoint>>
  >(
    () => [
      {
        header: 'Rank',
        accessorKey: 'rank',
        cell: (index) => (
          <div className="font-medium">{index.row.original.rank}</div>
        ),
        size: 30,
      },
      {
        header: 'Country',
        accessorKey: 'country',
        cell: (index) => (
          <div className="font-semibold flex gap-5 items-center">
            <CountryFlag
              className="mb-[3px]"
              name={index.row.original.countryCode}
              width={34}
            />
            <p>
              <VCMCountryNameShortRendering name={index.row.original.country} />
            </p>
          </div>
        ),
        enableSorting: false,
        size: 298,
      },
      {
        header: 'Overall score',
        accessorKey: 'score',
        cell: (index) => (
          <div className="font-bold">{index.row.original.score.toFixed(1)}</div>
        ),
        size: 105,
      },
      {
        header: 'Pillar score',
        accessorKey: 'investmentLandscapePillarScore',
        enableSorting: false,
        size: 104,
        accessorFn: (index: VCMIndexDataPoint) =>
          index.investmentLandscape.score,
      },

      createSubPillarColumnConfig(
        HEADER_TEXT.POLITICAL_STABILITY,
        (index: VCMIndexDataPoint) =>
          index.investmentLandscape.politicalStability,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.RECENT_COUP,
        (index: VCMIndexDataPoint) => index.investmentLandscape.recentCoup,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.INFLATION,
        (index: VCMIndexDataPoint) => index.investmentLandscape.inflation,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.TENURE_SECURITY,
        (index: VCMIndexDataPoint) => index.investmentLandscape.tenureSecurity,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.GOVERNMENT_EXPROPRIATION_RISK,
        (index: VCMIndexDataPoint) =>
          index.investmentLandscape.governmentExpropriationRisk,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.FINANCIAL_SANCTIONS,
        (index: VCMIndexDataPoint) =>
          index.investmentLandscape.financialSanctions,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.FOREIGN_DIRECT_INVESTMENT,
        (index: VCMIndexDataPoint) =>
          index.investmentLandscape.foreignDirectInvestment,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.RULE_OF_LAW,
        (index: VCMIndexDataPoint) => index.investmentLandscape.ruleOfLaw,
      ),
    ],
    [],
  )

  const climateNaturePeopleOpportunityColumns = useMemo<
    Array<ColumnDef<VCMIndexDataPoint>>
  >(
    () => [
      {
        header: 'Rank',
        accessorKey: 'rank',
        cell: (index) => (
          <div className="font-medium">{index.row.original.rank}</div>
        ),
        size: 30,
      },
      {
        header: 'Country',
        accessorKey: 'country',
        cell: (index) => (
          <div className="font-semibold flex gap-5 items-center">
            <CountryFlag
              className="mb-[3px]"
              name={index.row.original.countryCode}
              width={34}
            />
            <p>
              <VCMCountryNameShortRendering name={index.row.original.country} />
            </p>
          </div>
        ),
        enableSorting: false,
        size: 298,
      },
      {
        header: 'Overall score',
        accessorKey: 'score',
        cell: (index) => (
          <div className="font-bold">{index.row.original.score.toFixed(1)}</div>
        ),
        size: 105,
      },
      {
        header: 'Pillar score',
        accessorKey: 'climateNaturePeopleOpportunityPillarScore',
        enableSorting: false,
        size: 104,
        accessorFn: (index: VCMIndexDataPoint) =>
          index.climateNaturePeopleOpportunity.score,
      },

      createSubPillarColumnConfig(
        HEADER_TEXT.CLEAN_COOKING_OPPORTUNITY,
        (index: VCMIndexDataPoint) =>
          index.climateNaturePeopleOpportunity.cleanCookingOpportunity,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.ELECTRICITY_ACCESS_OPPORTUNITY,
        (index: VCMIndexDataPoint) =>
          index.climateNaturePeopleOpportunity.electricityAccessOpportunity,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.RENEWABLES_ADOPTION_OPPORTUNITY,
        (index: VCMIndexDataPoint) =>
          index.climateNaturePeopleOpportunity.renewablesAdoptionOpportunity,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.OPPORTUNITY_TO_REDUCE_DEFORESTATION,
        (index: VCMIndexDataPoint) =>
          index.climateNaturePeopleOpportunity.opportunityToReduceReforestation,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.TOTAL_BIOMASS,
        (index: VCMIndexDataPoint) =>
          index.climateNaturePeopleOpportunity.totalBiomass,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.BIOMASS_CONCENTRATION_IN_FORESTS,
        (index: VCMIndexDataPoint) =>
          index.climateNaturePeopleOpportunity.forestBiomassCapacity,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.BIODIVERSITY_CONSERVATION_POTENTIAL,
        (index: VCMIndexDataPoint) =>
          index.climateNaturePeopleOpportunity
            .biodiversityConservationPotential,
      ),
      createSubPillarColumnConfig(
        HEADER_TEXT.CO2_REDUCTION_OPPORTUNITY,
        (index: VCMIndexDataPoint) =>
          index.climateNaturePeopleOpportunity.CO2reductionOpportunity,
      ),
    ],
    [],
  )

  const OVERALL_VIEW = 'Pillar score overview'
  const GLOBAL_VIEW = 'Global carbon market readiness indicator breakdown'
  const INVESTMENT_VIEW = 'Investment landscape indicator breakdown'
  const CNP_VIEW = 'Climate, nature and people opportunity indicator breakdown'

  const tableViews = [OVERALL_VIEW, GLOBAL_VIEW, INVESTMENT_VIEW, CNP_VIEW]

  const subPillarColors = [
    'bg-brand-blue-100 !text-black',
    'bg-brand-blue-200 !text-black',
    'bg-brand-blue-300 !text-black',
    'bg-brand-blue-400 !text-white',
    'bg-brand-blue-500 !text-white',
  ]

  const [sorting, setSorting] = useState<SortingState>([])
  const [selectedTableView, setSelectedTableView] = useState(tableViews[0])

  const table = useReactTable({
    initialState: {},
    columns:
      selectedTableView === CNP_VIEW
        ? climateNaturePeopleOpportunityColumns
        : selectedTableView === GLOBAL_VIEW
          ? globalCarbonMarketReadinessColumns
          : selectedTableView === INVESTMENT_VIEW
            ? investmentLandscapeColumns
            : overallColumns,
    data: vcmIndexData,
    state: { sorting },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <div className="mt-4 text-center">
      <h3 className="text-center text-2xl mb-6 text-brand-blue-500 lg:ext-4xl">
        Top 40 breakdown
      </h3>
      <div className="mb-6 lg:w-[720px] mx-auto text-center text-xl text-brand-blue-500">
        {TABLE_BLURB}
      </div>
      <div
        className={cn(
          'bg-grey-100 rounded-t-lg border border-grey-200 flex justify-end items-center',
          selectedTableView !== OVERALL_VIEW && 'w-[1325px]',
        )}
      >
        <div className="font-medium text-grey-400">Select table view:</div>

        <div className="px-3.5 py-3 w-[982px] text-left relative">
          <Listbox value={selectedTableView} onChange={setSelectedTableView}>
            <ListboxButton
              className={cn(
                'block relative w-full px-4 py-3.5 text-lg font-semibold text-black bg-white border border-grey-200 rounded-lg',
                'focus:outline-none focus:ring-1 text-left cursor-pointer',
              )}
            >
              {selectedTableView}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <HiChevronDown
                  className="w-5 h-5 text-grey-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <ListboxOptions
              className={cn(
                'absolute z-10 px-3.5 py-3 w-[49.5em] mt-1 overflow-auto text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                selectedTableView !== OVERALL_VIEW && 'w-[57.5em]',
              )}
            >
              {tableViews.map((view) => (
                <ListboxOption
                  key={view}
                  value={view}
                  className={({ selected }) =>
                    cn(
                      'py-1 relative cursor-pointer select-none pl-8 pr-4',
                      selected && 'bg-mint',
                    )
                  }
                >
                  <>
                    {selectedTableView === view ? (
                      <span className="flex items-start pl-1.5 text-brand-blue-500 font-medium text-center justify-start">
                        <HiCheck
                          className="w-5 h-5 inline-block mr-5"
                          aria-hidden="true"
                        />
                        {view}
                      </span>
                    ) : (
                      <span className="font-medium">{view}</span>
                    )}
                  </>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Listbox>
        </div>
      </div>
      <table
        role="grid"
        className={
          // Please read before editing:
          //
          // we live edited this with Lucija to make everything pixel perfect for launch.
          // there are therefore lots of exact pixel values for specific columns, etc,
          // and it's generally not very well structured (there are likely some overlapping/redundant classes or ways things can be done omre straightforwardly)
          //
          // if we need to make *significant* changes to this, or reuse similar tables in the future, we should refactor this
          cn(
            'bg-white w-[1325px] block text-sm text-left border-l border-r border-b border-grey-200 rounded-b-lg',
            '[&_thead>tr>*]:text-black [&_th]:font-medium [&_th]:text-base [&_th]:bg-grey-100 [&_thead>tr>*:nth-child(1)]:align-center [&_thead>tr>*:nth-child(2)]:align-center [&_thead>tr>*:nth-child(3)]:align-center [&_thead>tr>*]:whitespace-pre-wrap',
            '[&_tr>*]:py-0 [&_tr>*]:h-[62px] [&_tr>*]:pr-5 [&_tr>*]:pl-5 [&_tr>*:nth-child(1)]:pr-0 [&_tr>*:nth-child(2)]:pl-0 [&_tr>*]:border-b [&_tr>*]:border-grey-200 [&_thead>tr>*]:text-left [&_tr>*]:text-grey-400 [&_tr>*:nth-child(2)]:text-black [&_tr>*:nth-child(3)]:border-r',
            'w-[1325px] [&_thead>tr>*]:px-0 [&_thead>tr>*]:text-[14px] [&_thead>tr>*]:min-w-[111px] [&_thead>tr>*:nth-child(1)]:min-w-[75px] [&_thead>tr>*:nth-child(1)]:max-w-[75px] [&_tr>*:nth-child(2)]:min-w-[200px] [&_tr>*:nth-child(2)]:pr-0 [&_thead>tr>*:nth-child(1)]:pl-3 [&_tr>*:nth-child(3)]:pr-0 [&_thead>tr>*:nth-child(3)]:max-w-[70px] [&_thead>tr>*:not:nth-child(2)]:max-w-[111px] [&_tr>*]:pr-0 [&_tr>*]:pl-0 [&_tr>*:nth-child(1)]:pl-5 [&_tr>*:nth-child(3)]:pl-0 [&_tr>*]:text-center [&_tr>*:nth-child(3)]:text-left [&_tr>*:nth-child(1)]:text-left [&_tr>*:nth-child(2)]:text-left [&_thead>tr>*:nth-child(3)]:text-left  [&_thead>tr>*:nth-child(1)]:text-left  [&_thead>tr>*:nth-child(2)]:text-left  [&_thead>tr>*]:text-center',
            '[&_thead>tr>*]:h-[95.5px] [&_tr>*:nth-child(4)]:min-w-[80px] [&_tr>*:nth-child(4)]:max-w-[80px] [&_tr>*:nth-child(3)]:min-w-[80px] [&_tr>*:nth-child(3)]:max-w-[80px] [&_thead>tr>*]:max-w-[111px] [&_tr>*:nth-child(n+4)]:min-w-[322.5px] [&_tr>*:nth-child(n+4)]:text-left [&_tr>*:nth-child(n+4)]:pl-4',
            selectedTableView !== OVERALL_VIEW &&
              'w-[1325px] [&_thead>tr>*]:px-0 [&_thead>tr>*]:min-w-[111px] [&_tr>*:nth-child(n+5)]:!min-w-[111px] [&_thead>tr>*:nth-child(1)]:min-w-[75px] [&_tr>*:nth-child(2)]:min-w-[200px] [&_tr>*:nth-child(2)]:pr-0 [&_thead>tr>*:nth-child(1)]:pl-3 [&_tr>*:nth-child(3)]:pr-0 [&_thead>tr>*:nth-child(3)]:max-w-[70px] [&_thead>tr>*:not:nth-child(2)]:max-w-[111px] [&_tr>*]:pr-0 [&_tr>*]:pl-0 [&_tr>*:nth-child(1)]:pl-5 [&_tr>*:nth-child(3)]:pl-0 [&_tr>*]:text-center [&_tr>*:nth-child(3)]:text-left [&_tr>*:nth-child(1)]:text-left [&_tr>*:nth-child(2)]:text-left [&_thead>tr>*:nth-child(3)]:text-left  [&_thead>tr>*:nth-child(1)]:text-left  [&_thead>tr>*:nth-child(2)]:text-left  [&_thead>tr>*]:text-center [&_tr>*:nth-child(4)]:!min-w-[80px] [&_tr>*:nth-child(4)]:!max-w-[80px] [&_tr>*:nth-child(3)]:min-w-[80px] [&_tr>*:nth-child(3)]:max-w-[80px] [&_thead>tr>*]:max-w-[111px] [&_tr>*:nth-child(n+5)]:text-center [&_tr>*:nth-child(n+5)]:pl-0 [&_tr>*:nth-child(n+4)]:pr-0',
          )
        }
      >
        <thead>
          <tr>
            {table.getHeaderGroups()[0]?.headers.map((header, i) => {
              const isSubPillarColumn =
                selectedTableView !== OVERALL_VIEW && i > 3

              return isSubPillarColumn ? (
                <IndexTableSubPillarHeader key={header.id} header={header} />
              ) : (
                <IndexTableHeader key={header.id} header={header} />
              )
            }) ?? null}
          </tr>
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, i) => {
            const isLastRow = i === table.getRowModel().rows.length - 1
            const cells = row.getVisibleCells()

            const getSubPillarColor = (
              cell: Cell<VCMIndexDataPoint, unknown>,
            ) => {
              const cellValue = cell.getValue()
              if (
                cellValue !== null &&
                cell.column.columnDef.header !== 'Rank' &&
                cell.column.columnDef.header !== 'Country'
              ) {
                invariant(typeof cellValue === 'number')

                return subPillarColors[cellValue - 1]
              }

              return 'bg-white'
            }

            return (
              <tr key={row.id}>
                {cells.map((cell, i) => {
                  const isFirstCell = i === 0
                  const isLastCell = i === cells.length - 1

                  return (
                    <td
                      key={cell.id}
                      className={cn(
                        getSubPillarColor(cell),
                        isLastRow && '!border-b-0',
                        isLastRow && isFirstCell && 'rounded-bl-[7px]',
                        isLastRow && isLastCell && 'rounded-br-[7px]',
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export const TABLE_BLURB =
  'The Index consists of three pillars, each scored out of 100, with each pillar comprising a series of indicators. Indicator scores are normalised here from one to five. Pillars and indicators have varying weights in determining the overall Index score.'

function IndexTableHeader(props: {
  header: Header<VCMIndexDataPoint, unknown>
}) {
  const sortDirection = props.header.column.getIsSorted()

  return (
    <th
      style={{
        width: props.header.column.getSize(),
      }}
      className={cn(props.header.column.getCanSort() && 'cursor-pointer')}
      key={props.header.id}
      onClick={props.header.column.getToggleSortingHandler()}
    >
      {flexRender(
        props.header.column.columnDef.header,
        props.header.getContext(),
      )}

      {props.header.column.getCanSort() ? (
        sortDirection == false ? (
          <HiChevronUpDown size={16} className="inline-block" />
        ) : sortDirection === 'desc' ? (
          <HiChevronDown size={16} className="inline-block" />
        ) : (
          <HiChevronUp size={16} className="inline-block" />
        )
      ) : null}
    </th>
  )
}

function getHeaderDescription(headerText: string) {
  switch (headerText) {
    case HEADER_TEXT.LEGACY_VCM_ISSUANCES:
      return 'Score reflects number of carbon credits issued from 2005 to 2023'

    case HEADER_TEXT.LEGACY_DEVELOPER_ECOSYSTEM:
      return 'Score reflects number of project developers that issued carbon credits from 2005 to 2023'

    case HEADER_TEXT.DEVELOPER_ECOSYSTEM_TODAY:
      return 'Score reflects number of VCM developers that issued credits from July 2021 to July 2023'

    case HEADER_TEXT.RECENT_SUPPLY_OF_IN_DEMAND_CREDITS:
      return 'Score reflects supply of projects from July 2021 to July 2023 with strong market demand'

    case HEADER_TEXT.CDM_EXPERIENCE:
      return 'Score reflects number of certified emission reduction credits issued from 2007 to 2023'

    case HEADER_TEXT.REDD_READINESS:
      return 'Score reflects engagement with FCPF, REDD+ and the Green Climate Fund'

    case HEADER_TEXT.FOREST_BASELINE_PROFICIENCY:
      return 'Score reflects number of forest reference emissions levels submitted'

    case HEADER_TEXT.ARTICLE_SIX_ENGAGEMENT:
      return 'Score reflects number of Article 6 pilot projects and bilateral agreements'

    case HEADER_TEXT.POLITICAL_STABILITY:
      return `Score reflects political stability ranking in the World Bank's Worldwide Governance Indicators`

    case HEADER_TEXT.RECENT_COUP:
      return 'Score reflects number of coup events in the country from 2021 to 2023'

    case HEADER_TEXT.TENURE_SECURITY:
      return 'Score reflects percentage of population who believe it is unlikely they could lose the right to use their property in the next five years'

    case HEADER_TEXT.GOVERNMENT_EXPROPRIATION_RISK:
      return `Score reflects expropriation risk ranking in Credendo's 2023 country risk assessment`

    case HEADER_TEXT.INFLATION:
      return 'Score reflects GDP deflator inflation rate in 2022'

    case HEADER_TEXT.FOREIGN_DIRECT_INVESTMENT:
      return 'Score reflects foreign direct investment per capita in 2021'

    case HEADER_TEXT.FINANCIAL_SANCTIONS:
      return 'Score reflects appearance in the UK, EU or US country sanctions list'

    case HEADER_TEXT.RULE_OF_LAW:
      return `Score reflects ranking in the World Justice Project's Rule of Law Index`

    case HEADER_TEXT.CLEAN_COOKING_OPPORTUNITY:
      return 'Low access to clean cooking technologies means a potential role for the VCM'

    case HEADER_TEXT.ELECTRICITY_ACCESS_OPPORTUNITY:
      return 'Low access to electricity means a potential role for the VCM'

    case HEADER_TEXT.RENEWABLES_ADOPTION_OPPORTUNITY:
      return 'A low level of renewables means a potential role for the VCM'

    case HEADER_TEXT.TOTAL_BIOMASS:
      return 'A high stock of living biomass means the VCM can help conserve this'

    case HEADER_TEXT.OPPORTUNITY_TO_REDUCE_DEFORESTATION:
      return 'High rates of deforestation mean a potential role for the VCM'

    case HEADER_TEXT.BIOMASS_CONCENTRATION_IN_FORESTS:
      return 'A high concentration of above ground biomass means the VCM can help conserve it'

    case HEADER_TEXT.BIODIVERSITY_CONSERVATION_POTENTIAL:
      return 'A higher number of threatened species means a potential role for the VCM'

    case HEADER_TEXT.CO2_REDUCTION_OPPORTUNITY:
      return 'Higher CO2 levels mean greater potential for the VCM to aid emissions reductions'

    default:
      return null
  }
}

function IndexTableSubPillarHeader(props: {
  header: Header<VCMIndexDataPoint, unknown>
}) {
  const triggerRef = useRef<HTMLTableCellElement>(null)

  const headerText = props.header.column.columnDef.header
  invariant(typeof headerText === 'string')

  const headerDescription = getHeaderDescription(headerText)

  return (
    <Tooltip.Root delayDuration={0}>
      <Tooltip.Trigger asChild>
        <th
          ref={triggerRef}
          style={{
            width: props.header.column.getSize(),
          }}
          className={cn(props.header.column.getCanSort() && 'cursor-pointer')}
          key={props.header.id}
          onClick={props.header.column.getToggleSortingHandler()}
        >
          {flexRender(
            props.header.column.columnDef.header,
            props.header.getContext(),
          )}
        </th>
      </Tooltip.Trigger>

      <Tooltip.Portal>
        <Tooltip.Content
          align="center"
          side="top"
          arrowPadding={2}
          className={`bg-white border border-grey-200 rounded-md shadow-[0_15px_50px_0_rgba(0,0,0,0.05)] p-2`}
          onPointerDownOutside={(event) => {
            if (triggerRef.current?.contains(event.target as Node)) {
              event.preventDefault()
            }
          }}
        >
          <div className="text-xs text-grey-400 leading-[14px] mb-3">
            Indicator
          </div>

          <div className="text-sm leading-[130%] font-semibold">
            {headerText}
          </div>

          {headerDescription && (
            <div className="text-sm leading-[130%] mt-2.5 max-w-[200px]">
              {headerDescription}
            </div>
          )}
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}

function IndexTableCellTooltip(props: {
  CellContent: React.ReactNode | null
  tooltip: {
    country: string
    subPillar: string
    score: string | number | null
  }
}) {
  const triggerRef = useRef<HTMLDivElement>(null)

  return (
    <Tooltip.Root delayDuration={0}>
      <Tooltip.Trigger asChild>
        <div
          ref={triggerRef}
          className={cn(
            'w-full h-full min-h-[59.5px] leading-[59.5px]',
            props.CellContent === null && 'bg-grey-100',
          )}
        >
          {props.CellContent === null ? '?' : props.CellContent}
        </div>
      </Tooltip.Trigger>

      <Tooltip.Portal>
        <Tooltip.Content
          align="center"
          side="top"
          arrowPadding={2}
          className={`bg-white border border-grey-200 rounded-md shadow-[0_15px_50px_0_rgba(0,0,0,0.05)] p-2 pt-2 pb-1.5`}
          onPointerDownOutside={(event) => {
            if (triggerRef.current?.contains(event.target as Node)) {
              event.preventDefault()
            }
          }}
        >
          <div className="text-sm leading-[130%] font-semibold mb-2.5">
            {props.tooltip.country}
          </div>
          <div className="text-sm leading-[130%] mb-3">
            {props.tooltip.subPillar}
          </div>
          <div className="flex justify-between items-baseline">
            <div className="text-xs text-grey-400 leading-[116%] min-w-[120px] mr-4">
              Indicator Score
            </div>

            {props.tooltip.score === null ? (
              <div className="leading-[14px] text-base font-semibold">
                No data
              </div>
            ) : (
              <div className="leading-[14px] text-sm">
                <span className="text-base font-semibold">
                  {props.tooltip.score}
                </span>
                {' /5'}
              </div>
            )}
          </div>
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}

export function VCMIndexTableMobile(props: Props) {
  const vcmIndexData = props.data

  const overallColumns = useMemo<Array<ColumnDef<VCMIndexDataPoint>>>(
    () => [
      {
        header: 'Rank',
        accessorKey: 'rank',
        cell: (index) => (
          <div className="font-medium">{index.row.original.rank}</div>
        ),
        size: 58,
      },
      {
        header: 'Country',
        accessorKey: 'country',
        cell: (index) => (
          <div className="font-semibold flex gap-3 items-center">
            <CountryFlag
              className="mb-[3px]"
              name={index.row.original.countryCode}
              width={34}
            />
            <p>
              <VCMCountryNameShortRendering name={index.row.original.country} />
            </p>
          </div>
        ),
        size: 211,
      },
      {
        header: 'Score',
        accessorKey: 'score',
        cell: (index) => (
          <div className="font-bold">{index.row.original.score.toFixed(1)}</div>
        ),
        size: 34,
      },
    ],
    [],
  )

  const table = useReactTable({
    initialState: {},
    columns: overallColumns,
    data: vcmIndexData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const [showPillarBreakdown, setShowPillarBreakdown] = useState(false)
  const [showPillarId, setShowPillarId] = useState('')

  function showBreakdown(id: string) {
    setShowPillarBreakdown(!showPillarBreakdown)
    setShowPillarId(id)
  }

  const headersData = table.getHeaderGroups()[0]?.headers

  return (
    <div>
      <div className="text-center max-w-[720px] mx-auto">
        <h2 className="text-2xl max-w-[1308px]">Top 40 breakdown</h2>
        <p className="mt-6">
          The Index consists of three pillars, each scored out of 100, with each
          pillar comprising a series of indicators. Indicator scores are
          normalised here from one to five. Pillars and indicators have varying
          weights in determining the overall Index score.
        </p>
        <p className="mt-4">
          <span className="font-bold">Note:</span> To view the full interactive
          functionality and additional data of the index open it on a larger
          screen device.
        </p>
      </div>
      <div className="max-w-[600px] mx-auto mt-6">
        <table
          role="grid"
          className={cn(
            'bg-white w-full block text-sm text-left border border-grey-200 rounded-lg',
            '[&_thead>tr>*]:text-black [&_th]:font-medium [&_th]:text-base [&_th]:bg-grey-100',
            '[&_thead>tr>*]:align-bottom [&_tr>*]:py-4 [&_tr>*]:pr-5 [&_tr>*]:pl-5 [&_tr>*:nth-child(1)]:!w-[58px]  [&_tr>*:nth-child(2)]:!min-w-[211px] [&_tr>*:nth-child(2)]:!w-screen [&_tr>*:nth-child(3)]:!w-[58px] [&_tr>*]:border-b [&_tr>*]:border-grey-200 [&_thead>tr>*]:text-left [&_tr>*]:text-grey-400 [&_tr>*:nth-child(2)]:text-black',
          )}
        >
          <thead>
            <tr>
              {headersData?.map((header, i) => {
                const isFirstCell = i === 0
                const isLastCell = i === headersData.length - 1

                return (
                  <th
                    style={{ width: header.column.getSize() }}
                    className={cn(
                      header.column.getCanSort() && 'cursor-pointer',
                      isFirstCell && 'rounded-tl-lg',
                      isLastCell && 'rounded-tr-lg',
                    )}
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                )
              }) ?? null}
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              const isLastRow = i === table.getRowModel().rows.length - 1
              const cells = row.getVisibleCells()

              return (
                <Fragment key={row.id}>
                  <tr
                    onClick={() => showBreakdown(row.id)}
                    className="bg-white"
                  >
                    {cells.map((cell, i) => {
                      const isFirstCell = i === 0
                      const isLastCell = i === cells.length - 1

                      return (
                        <td
                          key={cell.id}
                          className={cn(
                            isLastRow && '!border-b-0',
                            isLastRow && isFirstCell && 'rounded-bl-lg',
                            isLastRow && isLastCell && 'rounded-br-lg',
                            showPillarId === row.id &&
                              showPillarBreakdown &&
                              'bg-grey-100',
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      )
                    })}
                  </tr>

                  {showPillarBreakdown && showPillarId === row.id && (
                    <tr className="bg-grey-100">
                      <td colSpan={3} className="space-y-2">
                        <p className="text-grey-400 pt-3 pb-1">
                          Overall score breakdown:
                        </p>
                        <div className="flex justify-between text-black font-medium text-sm">
                          <span>Global carbon market readiness </span>
                          <span className=" w-[44.36px]">
                            {vcmIndexData[
                              row.index
                            ]?.globalCarbonMarketReadiness.score.toFixed(1)}
                          </span>
                        </div>
                        <div className="flex justify-between text-black font-medium text-sm">
                          <span>Investment landscape</span>
                          <span className=" w-[44.36px]">
                            {vcmIndexData[
                              row.index
                            ]?.investmentLandscape.score.toFixed(1)}
                          </span>
                        </div>
                        <div className="flex justify-between text-black font-medium text-sm">
                          <span>Climate, nature and people opportunity</span>
                          <span className=" w-[44.36px]">
                            {vcmIndexData[
                              row.index
                            ]?.climateNaturePeopleOpportunity.score.toFixed(1)}
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
