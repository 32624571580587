import { useEffect, useRef } from 'react'

/**
 * Sets up a window event listener.
 *
 * @param eventType The name of the event to listen for.
 * @param callbackFn The callback function to call when the event is fired.
 */
export function useEventListener<K extends keyof WindowEventMap>(
  eventType: K,
  callbackFn: (event: WindowEventMap[K]) => void,
) {
  const savedHandler = useRef(callbackFn)

  useEffect(() => {
    savedHandler.current = callbackFn
  }, [callbackFn])

  useEffect(() => {
    function eventHandler(event: WindowEventMap[K]) {
      savedHandler.current(event)
    }

    window.addEventListener(eventType, eventHandler)

    return () => {
      window.removeEventListener(eventType, eventHandler)
    }
  }, [eventType])
}
