import { VCMCountryNameShortRendering } from './VCMIndexTable'

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react'
import { Link } from '@remix-run/react'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  defaults,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { HiCheck, HiChevronDown } from 'react-icons/hi'
import invariant from 'tiny-invariant'

import tailwindConfig from 'tailwind.config'
import { colors } from '~/lib/colors'
import { cn } from '~/lib/utils'
import { VCMIndexDataPoint } from '~/services/vcmIndex.server'

defaults.font.family = 'sans-serif'

// Register all plugins we used on various charts throughout the app
function registerChartjs() {
  Chart.register(
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Filler,
    ArcElement,
    RadialLinearScale,
    ChartDataLabels,
  )

  Chart.defaults.set('plugins.datalabels', {
    display: false,
  })
}

registerChartjs()

type Props = {
  data: VCMIndexDataPoint[]
}

const OVERALL_VIEW = 'Overall VCM investment attractiveness'
const GLOBAL_VIEW = 'Global carbon market readiness pillar'
const INVESTMENT_VIEW = 'Investment landscape pillar'
const CNP_VIEW = 'Climate, nature and people opportunity pillar'

const chartViews = [OVERALL_VIEW, GLOBAL_VIEW, INVESTMENT_VIEW, CNP_VIEW]

export function VCMIndexChart(props: Props) {
  const chartData = props.data
  const [selectedChartView, setSelectedChartView] = useState(chartViews[0])

  const labels = chartData.map((country) => {
    // use the same labels in the chart as the table
    return VCMCountryNameShortRendering({
      name: country.country,
    })
  })

  const datasets = [
    {
      data: props.data.map((country) =>
        selectedChartView === OVERALL_VIEW
          ? country.score
          : selectedChartView === GLOBAL_VIEW
            ? country.globalCarbonMarketReadiness.score
            : selectedChartView === INVESTMENT_VIEW
              ? country.investmentLandscape.score
              : country.climateNaturePeopleOpportunity.score,
      ),
      color: colors.black,
      backgroundColor: colors['brand-blue'][200],
      hoverBackgroundColor: colors['brand-blue'][400],
      borderWidth: 0,
      barThickness: 20,
    },
  ]

  return (
    <div className="rounded-lg px-20 pt-[58px] pb-12 w-full">
      <div className="flex items-start flex-wrap">
        <div className="flex w-full relative">
          <div className="pl-1 pr-3.5 py-3 w-[50%] absolute left-20">
            <Listbox value={selectedChartView} onChange={setSelectedChartView}>
              <ListboxButton
                className={cn(
                  'block relative w-full px-4 py-3.5 text-lg text-black bg-white border border-grey-200 rounded-lg',
                  'focus:outline-none focus:ring-1 text-left',
                )}
              >
                {selectedChartView}
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <HiChevronDown
                    className="w-5 h-5 text-grey-400"
                    aria-hidden="true"
                  />
                </span>
              </ListboxButton>
              <ListboxOptions
                className={cn(
                  'absolute z-10 px-3.5 py-3 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                )}
              >
                {chartViews.map((view) => (
                  <ListboxOption
                    key={view}
                    value={view}
                    className={({ selected }) =>
                      cn(
                        'py-1 relative cursor-default select-none pl-8 pr-4',
                        selected && 'bg-mint',
                      )
                    }
                  >
                    {selectedChartView === view ? (
                      <span className="flex items-start pl-1.5 text-brand-blue-500 font-medium text-center justify-start">
                        <HiCheck
                          className="w-5 h-5 inline-block mr-5"
                          aria-hidden="true"
                        />
                        {view}
                      </span>
                    ) : (
                      <span className="font-medium">{view}</span>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Listbox>
          </div>
          <div className="font-semibold text-lg -mr-5 pt-7">Score</div>
          <div className="w-[1308px] min-w-[938px]">
            <Bar
              aria-label="Top 40 ranking countries and Index scores"
              height={700}
              plugins={[ChartDataLabels, Tooltip]}
              options={{
                animation: false,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    formatter: function (_value, context) {
                      return labels[context.dataIndex]
                    },
                    rotation: 270,
                    align: 'end',
                    anchor: 'start',
                    display: true,
                    color: (context) => (context.active ? 'white' : 'black'),
                    labels: {
                      title: {
                        font: {
                          size: 14,
                          weight: 400,
                        },
                      },
                    },
                  },
                  tooltip: {
                    backgroundColor: 'white',
                    borderWidth: 1,
                    padding: 12,
                    borderColor: tailwindConfig.theme.colors.grey[200],
                    titleColor: tailwindConfig.theme.colors.black,
                    titleFont: {
                      size: 20,
                    },
                    bodyFont: {
                      size: 16,
                    },
                    titleMarginBottom: 12,
                    bodyColor: tailwindConfig.theme.colors.grey[400],
                    caretSize: 0, // don't show an arrow on the tooltip
                    displayColors: false, // don't show the legend square in the label
                    enabled: (ctx) => {
                      // The type of tooltip is incorrect as it can be undefined
                      const tooltip = ctx.tooltip as
                        | typeof ctx.tooltip
                        | undefined
                      if (!tooltip) return true

                      const value = tooltip.dataPoints[0]?.raw
                      invariant(typeof value === 'number')

                      return value < 0.04
                    },
                    callbacks: {
                      title: (context) => {
                        const dataIndex = context[0]?.dataIndex
                        invariant(dataIndex != undefined)
                        const titleString =
                          String(dataIndex + 1) +
                          ' - ' +
                          String(context[0]?.label)

                        return titleString
                      },
                      label: (context) => {
                        const scoreString =
                          (selectedChartView === OVERALL_VIEW
                            ? 'Overall score: '
                            : 'Pillar score: ') + context.formattedValue

                        return scoreString
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    border: {
                      color: '#848484',
                    },
                    grid: {
                      display: false,
                    },
                    title: {
                      text: 'Overall rank',
                      display: false,
                    },
                    ticks: {
                      font: {
                        size: 14,
                      },
                      callback: function (_value, index) {
                        if ((index + 1) % 5 === 0 || index == 0) {
                          return index + 1
                        } else {
                          return
                        }
                      },
                    },
                  },
                  y: {
                    border: {
                      color: '#848484',
                    },
                    grid: {
                      display: false,
                    },
                    min: 0,
                    max: selectedChartView == OVERALL_VIEW ? 92 : 119,
                    stacked: true,
                    title: {
                      text: 'Score',
                      display: false,
                    },
                    ticks: {
                      includeBounds: false,
                      stepSize: 20,
                      font: {
                        size: 14,
                      },
                    },
                  },
                },
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
              }}
              data={{
                labels,
                datasets,
              }}
              color={colors.black}
            />
          </div>
        </div>
        <div className="text-right text-lg font-semibold grow-0 shrink-0 basis-full w-full pt-3 min-w-[968px] flex justify-between items-baseline">
          <Link
            to="#methodology"
            className="text-grey-400 hover:text-brand-green-400 font-medium underline cursor-pointer"
          >
            Our methodology
          </Link>
          <span>Overall rank</span>
        </div>
      </div>
    </div>
  )
}

export function VCMIndexChartMobile(props: Props) {
  const chartData = props.data
  const [selectedChartView, setSelectedChartView] = useState(chartViews[0])

  const labels = chartData.map((country) => {
    // use the same labels in the chart as the table
    return VCMCountryNameShortRendering({
      name: country.country,
    })
  })

  const datasets = [
    {
      data: chartData.map((country) =>
        selectedChartView === OVERALL_VIEW
          ? country.score
          : selectedChartView === GLOBAL_VIEW
            ? country.globalCarbonMarketReadiness.score
            : selectedChartView === INVESTMENT_VIEW
              ? country.investmentLandscape.score
              : country.climateNaturePeopleOpportunity.score,
      ),
      yAxisId: 'top-axis',
      color: colors.black,
      backgroundColor: colors['brand-blue'][200],
      hoverBackgroundColor: colors['brand-blue'][400],
      hoverBorderColor: '#00000',
      borderWidth: 0,
      barThickness: 20,
    },
  ]

  return (
    <>
      <div className="flex flex-col">
        <div className="font-semibold text-lg -mr-2 text-left pt-7 pl-4 lg:pl-0">
          Score
        </div>
        <div className={cn('px-3.5 py-3 w-full')}>
          <Listbox value={selectedChartView} onChange={setSelectedChartView}>
            <Listbox.Button
              className={cn(
                'block relative w-full px-4 py-3.5 text-lg  text-black bg-white border border-grey-200 rounded-lg',
                'focus:outline-none focus:ring-1 text-left',
              )}
            >
              {selectedChartView}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <HiChevronDown
                  className="w-5 h-5 text-grey-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Listbox.Options
              className={cn(
                'absolute z-10 px-3.5 py-3 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
              )}
            >
              {chartViews.map((view) => (
                <Listbox.Option
                  key={view}
                  value={view}
                  className={({ active }) =>
                    cn(
                      'py-1 relative cursor-default select-none pl-8 pr-4',
                      active && 'bg-mint',
                    )
                  }
                >
                  <>
                    {selectedChartView === view ? (
                      <span className="flex items-start pl-1.5 text-brand-green-400 font-medium text-center justify-start">
                        <HiCheck
                          className="w-5 h-5 inline-block mr-5"
                          aria-hidden="true"
                        />
                        {view}
                      </span>
                    ) : (
                      <span className="font-medium">{view}</span>
                    )}
                  </>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <div className="w-full">
          <Bar
            aria-label="Top 40 ranking countries and Index scores"
            plugins={[ChartDataLabels, Tooltip]}
            height={1112}
            options={{
              animation: false,
              indexAxis: 'y',
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  formatter: function (_value, context) {
                    return labels[context.dataIndex]
                  },
                  align: 'end',
                  anchor: 'start',
                  display: true,
                  color: 'white',
                  labels: {
                    title: {
                      font: {
                        size: 12,
                        weight: 400,
                      },
                    },
                  },
                },
                tooltip: {
                  backgroundColor: 'white',
                  padding: 8,
                  borderWidth: 1,
                  borderColor: tailwindConfig.theme.colors.grey[200],
                  titleColor: tailwindConfig.theme.colors.black,
                  titleFont: {
                    size: 16,
                  },
                  bodyFont: {
                    size: 14,
                  },
                  titleMarginBottom: 10,
                  bodyColor: tailwindConfig.theme.colors.grey[400],
                  caretSize: 0, // don't show an arrow on the tooltip
                  displayColors: false, // don't show the legend square in the label
                  enabled: (ctx) => {
                    // The type of tooltip is incorrect as it can be undefined
                    const tooltip = ctx.tooltip as
                      | typeof ctx.tooltip
                      | undefined
                    if (!tooltip) return true

                    const value = tooltip.dataPoints[0]?.raw
                    invariant(typeof value === 'number')

                    return value < 0.04
                  },
                  callbacks: {
                    title: (context) => {
                      const dataIndex = context[0]?.dataIndex
                      invariant(dataIndex != undefined)
                      const titleString =
                        String(dataIndex + 1) +
                        ' - ' +
                        String(context[0]?.label)

                      return titleString
                    },
                    label: (context) => {
                      const scoreString =
                        'Overall score: ' + context.formattedValue

                      return scoreString
                    },
                  },
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  display: false,
                  type: 'linear',
                  position: 'left',
                  title: {
                    text: 'Overall rank',
                    display: false,
                  },
                },
                'top-axis': {
                  grid: {
                    display: false,
                  },
                  type: 'linear',
                  position: 'top',
                  min: 0,
                  max: 99,
                  stacked: true,
                  title: {
                    text: 'Score',
                    display: false,
                  },
                  ticks: {
                    includeBounds: false,
                    stepSize: 20,
                    font: {
                      size: 12,
                    },
                  },
                },
                y: {
                  grid: {
                    display: false,
                  },
                  display: true,
                  ticks: {
                    font: {
                      size: 12,
                    },
                    callback: function (_value, index) {
                      if ((index + 1) % 5 === 0 || index == 0) {
                        return index + 1
                      } else {
                        return
                      }
                    },
                  },
                },
              },
            }}
            data={{
              labels,
              datasets,
            }}
          />
        </div>

        <Link
          to="#methodology"
          className="text-grey-400 hover:text-brand-blue-500 text-xs font-medium underline cursor-pointer py-7"
        >
          Our methodology
        </Link>
      </div>
    </>
  )
}
